// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contatti-tsx": () => import("./../../../src/pages/contatti.tsx" /* webpackChunkName: "component---src-pages-contatti-tsx" */),
  "component---src-pages-dove-siamo-tsx": () => import("./../../../src/pages/dove-siamo.tsx" /* webpackChunkName: "component---src-pages-dove-siamo-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-modulo-contatti-inviato-tsx": () => import("./../../../src/pages/modulo-contatti-inviato.tsx" /* webpackChunkName: "component---src-pages-modulo-contatti-inviato-tsx" */),
  "component---src-pages-servizi-tsx": () => import("./../../../src/pages/servizi.tsx" /* webpackChunkName: "component---src-pages-servizi-tsx" */),
  "component---src-templates-markdown-page-tsx": () => import("./../../../src/templates/MarkdownPage.tsx" /* webpackChunkName: "component---src-templates-markdown-page-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/ProductPage.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-works-category-page-tsx": () => import("./../../../src/templates/WorksCategoryPage.tsx" /* webpackChunkName: "component---src-templates-works-category-page-tsx" */)
}

